import React from "react";
import PropTypes from "prop-types";

// import Image from "components/Image";
import { Container, Row, Col } from "react-bootstrap";
import * as SocialIcons from "components/SocialIcons";


import "./Footer.scss";

const Footer = ({ frontmatter }) => {
  if (!frontmatter) {
    return null;
  }
  const {
    copyright,
    // privacyHref,
    // privacyText,
    // termsHref,
    // termsText,
    social: { facebook, github, linkedin, medium, twitter, instagram, youtube },
    // logoSponsor,
    // logoSponsorAlt,
    // logoSponsorSubHeader,
  } = frontmatter;

  return (
    <footer className="footer py-3">
      <Container>
        <Row className="align-items-center text-center">
          <Col lg={6} className="text-lg-left">
            {copyright}
          </Col>
          <Col lg={6} className="my-3 my-lg-0">
            {twitter ? <SocialIcons.Twitter userName={twitter} /> : null}
            {facebook ? <SocialIcons.Facebook userName={facebook} /> : null}
            {linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null}
            {instagram ? <SocialIcons.Instagram userName={instagram} /> : null}
            {github ? <SocialIcons.Github userName={github} /> : null}
            {medium ? <SocialIcons.Medium userName={medium} /> : null}
            {youtube ? <SocialIcons.Youtube userName={youtube} /> : null}
          </Col>
          {/*
          <Col lg={4}> */}
{/*            <div>
              <Image className="image" fileName={logoSponsor} alt={logoSponsorAlt} />
            </div>
            <div className="text-sm-center logoSponsorSubheader" dangerouslySetInnerHTML={{__html: logoSponsorSubHeader}} />
*/}


            {/*
          <a className="mr-3" href={privacyHref}>
              {privacyText}
            </a>
            <a href={termsHref}>{termsText}</a> */}
            {/*
          </Col>
          */}
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  frontmatter: PropTypes.object,
};

Footer.defaultProps = {
  frontmatter: null,
};

export default Footer;
